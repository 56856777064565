import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from 'gatsby';
import { AdvantagesList, Background, BenefitsList, Button, Card, Container, CTABox, ExtensionInfo, HeadingHighlight, HeadingWithImages, HeroMedia, ImageBox, Label, LayeredImage, Layout, OfferBox, OpinionBox, Price, Slider, Spacer } from 'components';
import big1 from 'images/layered-images/big1.svg';
import big2 from 'images/layered-images/big2.svg';
import big3 from 'images/layered-images/big3.svg';
import small1 from 'images/layered-images/small1.svg';
import small2 from 'images/layered-images/small2.svg';
import small3 from 'images/layered-images/small3.svg';
import { DocsIcon, FormattingIcon, PrivacyIcon, SecurityIcon, SynchronizationIcon, ThemeIcon } from 'images';
import videoPreview from 'images/promo-preview.gif';
export const query = graphql`
	query {
		alwaysAtHandImage: file(
			relativePath: { eq: "card-backgrounds/always-at-hand.svg" }
		) {
			...imageData
		}
		fasterThanOtherImage: file(
			relativePath: { eq: "card-backgrounds/faster-than-other.svg" }
		) {
			...imageData
		}
		mathesYourTasteImage: file(
			relativePath: { eq: "card-backgrounds/matches-your-taste.svg" }
		) {
			...imageData
		}
		personImage: file(
			relativePath: { eq: "opinion-box/person-avatar.png" }
		) {
			...imageData
		}
		slider1: file(relativePath: { eq: "slider/slider1.svg" }) {
			...imageData
		}
		slider2: file(relativePath: { eq: "slider/slider2.svg" }) {
			...imageData
		}
		slider3: file(relativePath: { eq: "slider/slider3.svg" }) {
			...imageData
		}
	}
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Spacer height={132} responsive={{
      md: 100,
      sm: 100
    }} mdxType="Spacer" />
    <Layout gap={30} itemWidths={[45, 55]} itemMaxWidths={[480, 630]} verticalAlignResponsive="reverse" mdxType="Layout">
	<div className="hero-content">
		<HeadingHighlight color="banana99" mdxType="HeadingHighlight">
			Loved by devs, | easy for *everyone*
		</HeadingHighlight>
		<p className="layout-description layout-description-video style:pfeatured">
			MadNotes is a minimalistic markdown notepad. Opened in a new tab,
			always at hand, fast to write, private if needed.
		</p>
		<ExtensionInfo mdxType="ExtensionInfo" />
	</div>
	<div className="layout-image-wrapper">
		<HeroMedia videoUrl="https://www.youtube.com/watch?v=xx_-obBMmvw&ab_channel=DepartmentofWeb" preview={videoPreview} mdxType="HeroMedia" />
	</div>
    </Layout>
    <Spacer height={100} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />
    <Layout gap={50} itemMaxWidths={[290, 290, 290]} verticalAlign="stretch" mdxType="Layout">
	<Card image={props.data.alwaysAtHandImage} title="Always at hand" description="Just open a new browser tab and the notepad is ready for you within a split of a second" mdxType="Card" />
	<Card image={props.data.mathesYourTasteImage} title="Matches your taste" description="Dark or light mode? Both are minimalistic and beautiful, waiting to be switched" mdxType="Card" />
	<Card image={props.data.fasterThanOtherImage} title="Faster than others" description="Formatting with an easy markdown markup for freakin’ fast idea capturing" mdxType="Card" />
    </Layout>
    <Spacer height={121} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />
    <Layout gap={50} itemMaxWidths={[580, 480]} mdxType="Layout">
	<LayeredImage backgroundImage={big1} foregroundImage={small1} foregroundOffset={{
        x: 11,
        y: 16
      }} foregroundAlign="bottom-right" color="banana99" position="left" responsiveForegroundOffset={{
        sm: {
          x: 1,
          y: 17
        }
      }} mdxType="LayeredImage" />
	<div>
		<HeadingHighlight color="grape99" mdxType="HeadingHighlight">
			Intuitive *markdown* notepad
		</HeadingHighlight>
		<p className="layout-description style:pfeatured">
			Don't take off your hands from the keyboard to format your text. Use
			a hotkey to insert heading or list. Writing was never easier and
			faster. First time hearing? Check our short guideline.
		</p>
		<Button color="grape99" to="/markdown" type="text" mdxType="Button">
			Learn more
		</Button>
	</div>
    </Layout>
    <Spacer height={150} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />
    <Layout gap={50} itemMaxWidths={[480, 580]} verticalAlignResponsive="reverse" mdxType="Layout">
	<div>
		<HeadingHighlight color="berry80" mdxType="HeadingHighlight">
			Multiple *documents* you can search and organize
		</HeadingHighlight>
		<p className="layout-description style:pfeatured">
			Clean up your memo mess! Color-coded notes and simple nesting in
			folders for an even quicker workflow.
		</p>
		<Button color="berry80" to="#usage" type="text" mdxType="Button">
			See All Features
		</Button>
	</div>
	<LayeredImage backgroundImage={big2} foregroundImage={small2} foregroundOffset={{
        x: -5,
        y: 35
      }} foregroundAlign="bottom-right" color="grape80" position="right" responsiveForegroundOffset={{
        sm: {
          x: -5,
          y: 35
        },
        xs: {
          x: 1,
          y: 35
        }
      }} mdxType="LayeredImage" />
    </Layout>
    <Spacer height={150} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />
    <Layout gap={50} itemMaxWidths={[580, 480]} mdxType="Layout">
	<LayeredImage backgroundImage={big3} foregroundImage={small3} foregroundOffset={{
        x: 3,
        y: 20
      }} foregroundAlign="bottom-left" foregroundWidth={77.6} color="berry80" position="left" responsiveForegroundOffset={{
        sm: {
          x: 10,
          y: 20
        }
      }} mdxType="LayeredImage" />
	<div>
		<HeadingHighlight color="banana99" mdxType="HeadingHighlight">
			*Secure* and private by default
		</HeadingHighlight>
		<p className="layout-description style:pfeatured">
			Keep your stuff safe as in your head! We use strong AES-256
			encryption to sync your notes in the cloud, or you keep them on your
			local machine.
		</p>
		<Button color="banana99" to="/security" type="text" mdxType="Button">
			Learn more
		</Button>
	</div>
    </Layout>
    <Spacer height={135} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />
    <CTABox color="banana99" description="To supercharge your workflow, subscribe to the MadNotes newsletter for exclusive tips, tactics, and app updates. Don't miss out – join our community of go-getters today!" heading="Unlock your inner productivity hustler!" mdxType="CTABox">
	<Button arrow="right" color="berry99" to="/newsletter" type="plain" mdxType="Button">
		Join Newsletter
	</Button>
    </CTABox>
    <div id="usage"></div>
    <Spacer height={100} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />
    <HeadingHighlight color="grape99" isCentered mdxType="HeadingHighlight">
	Don’t lose your *stuff*
    </HeadingHighlight>
    <Spacer height={60} responsive={{
      md: 50,
      sm: 40
    }} mdxType="Spacer" />
    <Layout gap={30} itemWidths={[55, 45]} itemMaxWidths={[582, 478]} verticalAlign="stretch" mdxType="Layout">
	<Slider slides={[{
        color: 'berry80',
        description: 'Perfect for saving stuff ”for later” as you go.',
        heading: 'Save links & snippets',
        image: props.data.slider1
      }, {
        color: 'banana99',
        description: 'Keep track of your daily tasks, shopping list, or future goals.',
        heading: 'Accessible to-do lists',
        image: props.data.slider2
      }, {
        color: 'grape99',
        description: 'Simple but powerful. Find anything in no-time.',
        heading: 'Quick search',
        image: props.data.slider3
      }]} mdxType="Slider" />
	<OpinionBox description={['“What should the dev do if no existing app works for him? Complain? Meh. He should develop his own!', 'We came up with our version of a notepad that’s beautiful, fast, and always ready for use in a new browser tab. If you hate waiting for a note app to boot, you’ll love it.', 'We present you the MadNotes App from the Department of Web.”']} personName="Jakub Mikita" personTitle="Department of Web" avatar={props.data.personImage} mdxType="OpinionBox" />
    </Layout>
    <Spacer height={100} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />
    <HeadingWithImages color="berry80" description="The new quality of writing your thoughts with MadNotes Premium Account. Get access to all features unlimited" heading="6 *Reasons* to start using MadNotes App" mdxType="HeadingWithImages" />
    <Spacer height={100} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />
    <AdvantagesList items={[{
      description: 'Delightful writing experience with intuitive markdown.',
      icon: FormattingIcon,
      title: 'Rich text formatting'
    }, {
      description: 'Available on every device and every major browser.',
      icon: SynchronizationIcon,
      title: 'Synchronization'
    }, {
      description: 'Limitless space for your creativity. MadNotes holds all your notes.',
      icon: DocsIcon,
      title: 'Unlimited docs'
    }, {
      description: 'Stored locally or hashed in the cloud with a key only you know.',
      icon: SecurityIcon,
      title: 'Security by default'
    }, {
      description: 'Instant eye relief? We have a color scheme just for you.',
      icon: ThemeIcon,
      title: 'Dark & Light Mode'
    }, {
      description: 'No more sneak peeks. Be confident while sharing your screen as notes boots up blurred.',
      icon: PrivacyIcon,
      title: 'Privacy Settings'
    }]} mdxType="AdvantagesList" />
    <Container position="relative" mdxType="Container">
	<Background direction="fade-to-bottom" offset={{
        x: 0,
        y: -80
      }} position="center" responsiveOffset={{
        lg: {
          x: 0,
          y: 400
        },
        md: {
          x: 0,
          y: 500
        },
        sm: {
          x: 0,
          y: 600
        },
        xs: {
          x: 0,
          y: 680
        }
      }} size="large" mdxType="Background" />
	<div id="pricing"></div>
	<Spacer height={188} responsive={{
        md: 80,
        sm: 60
      }} mdxType="Spacer" />
	<HeadingHighlight isCentered mdxType="HeadingHighlight">
		The *best* markdown notepad, | for the *best price*
	</HeadingHighlight>
	<Spacer height={60} responsive={{
        md: 50,
        sm: 40
      }} mdxType="Spacer" />
	<Layout gap={36} id="pricing" verticalAlign="stretch" mdxType="Layout">
		<ImageBox mdxType="ImageBox">Choose what’s right | for you</ImageBox>
		<OfferBox benefits={<BenefitsList color="banana99" items={['No registration', 'Secure local copy', 'Rich text formatting', 'Single document']} title="What you’ll get:" mdxType="BenefitsList" />} button={<Button color="berry80" to="https://use.madnotes.app" type="plain" mdxType="Button">
					Go to App
				</Button>} heading={{
          description: 'Try the MadNotes App without an account and commitment.',
          title: 'Free Starter'
        }} label={<Label color="banana99" content="Free Forever" mdxType="Label" />} price={<Price annotation="" color="grape99" mdxType="Price" />} mdxType="OfferBox" />
		<OfferBox benefits={<BenefitsList color="grape99" items={['Note synchronization', 'Multiple documents with individual settings', 'Privacy options', '...and more!']} title="What you’ll get:" mdxType="BenefitsList" />} button={<Button color="berry80" to="https://use.madnotes.app/subscribe" type="plain" mdxType="Button">
					Subscribe
				</Button>} heading={{
          description: 'Full package for a bargain',
          title: 'Premium'
        }} label={<Label color="grape99" content="All Features Unlimited" mdxType="Label" />} price={<Price annotation="" color="grape99" value={3} mdxType="Price" />} mdxType="OfferBox" />
	</Layout>
    </Container>
    <Spacer height={136} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      